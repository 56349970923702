import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"

export default function Privacy() {
  return (
    <Layout>
      <Seo title="Privacy Policy" />
      <section className="wrapper">
        <div className="container py-8 md:py-16 max-w-3xl space-y-8">
          <h1>Privacy Policy</h1>
          <div className="space-y-2">
            <p>Your privacy is critically important to us.</p>
            <p>
              Builder Finders Pty Ltd is located at: PO Box 4715 Sylvania Waters
              NSW 2224
            </p>
            <p>
              Welcome to our privacy policy Your privacy is critically important
              to us. It is Builder Finders Pty Ltd.’s policy
              to respect your privacy regarding any information we may collect
              while operating our website. This Privacy Policy applies to
              builderfinders.com.au/ (hereinafter, “us”, “we”, or
              “builderfinders.com.au/”). We respect your privacy and are
              committed to protecting personally identifiable information you
              may provide us through the Website. We have adopted this privacy
              policy (“Privacy Policy”) to explain what information may be
              collected on our Website, how we use this information, and under
              what circumstances we may disclose the information to third
              parties. This Privacy Policy applies only to information we
              collect through the Website and does not apply to our collection
              of information from other sources.
            </p>
            <p>
              This Privacy Policy, together with the Terms and conditions posted
              on our Website, set forth the general rules and policies governing
              your use of our Website. Depending on your activities when
              visiting our Website, you may be required to agree to additional
              terms and conditions.
            </p>
          </div>
          <div className="space-y-4">
            <div className="space-y-2">
              <h3>Website Visitors</h3>
              <p>
                Like most website operators, Builder Finders Pty Ltd collects
                non-personally-identifying information of the sort that web
                browsers and servers typically make available, such as the
                browser type, language preference, referring site, and the date
                and time of each visitor request. Builder Finders Pty Ltd.’s
                purpose in collecting non-personally identifying information is
                to better understand how Builder Finders Pty Ltd.’s visitors use
                its website. From time to time, Builder Finders Pty Ltd may
                release non-personally-identifying information in the aggregate,
                e.g., by publishing a report on trends in the usage of its
                website.
              </p>
              <p>
                Builder Finders Pty Ltd also collects potentially
                personally-identifying information like Internet Protocol (IP)
                addresses for logged in users and for users leaving comments on
                https://builderfinders.com.au/ blog posts. Builder Finders Pty
                Ltd only discloses logged in user and commenter IP addresses
                under the same circumstances that it uses and discloses
                personally-identifying information as described below.
              </p>
            </div>
            <div className="space-y-2">
              <h3>Gathering of personally-identifying information</h3>
              <p>
                Certain visitors to Builder Finders Pty Ltd.’s websites choose
                to interact with Builder Finders Pty Ltd in ways that require
                Builder Finders Pty Ltd to gather personally-identifying
                information. The amount and type of information that Builder
                Finders Pty Ltd gathers depends on the nature of the
                interaction. For example, we ask visitors who sign up for a blog
                at https://builderfinders.com.au/ to provide a username and
                email address.
              </p>
            </div>
            <div className="space-y-2">
              <h3>Security</h3>
              <p>
                The security of your Personal Information is important to us but
                remember that no method of transmission over the Internet, or
                method of electronic storage is 100% secure. While we strive to
                use commercially acceptable means to protect your Personal
                Information, we cannot guarantee its absolute security.
              </p>
            </div>
            <div className="space-y-2">
              <h3>Links to external sites</h3>
              <p>
                Our Service may contain links to external sites that are not
                operated by us. If you click on a third-party link, you will be
                directed to that third party’s site. We strongly advise you to
                review the Privacy Policy and terms and conditions of every site
                you visit.
              </p>
              <p>
                We have no control over, and assume no responsibility for the
                content, privacy policies or practices of any third-party sites,
                products or services.
              </p>
            </div>
            <div className="space-y-2">
              <h3>Protection of certain personally-identifying information</h3>
              <p>
                Builder Finders Pty Ltd discloses potentially
                personally-identifying and personally-identifying information
                only to those of its employees, contractors and affiliated
                organisations that (i) need to know that information in order to
                process it on Builder Finders Pty Ltd.’s behalf or to provide
                services available at Builder Finders Pty Ltd.’s website, and
                (ii) that have agreed not to disclose it to others. Some of
                those employees, contractors and affiliated organisations may be
                located outside of your home country; by using Builder Finders
                Pty Ltd.’s website, you consent to the transfer of such
                information to them. Builder Finders Pty Ltd will not rent or
                sell potentially personally-identifying and
                personally-identifying information to anyone. Other than to its
                employees, contractors and affiliated organisations, as
                described above, Builder Finders Pty Ltd discloses potentially
                personally-identifying and personally-identifying information
                only in response to a subpoena, court order or other
                governmental request, or when Builder Finders Pty Ltd believes
                in good faith that disclosure is reasonably necessary to protect
                the property or rights of Builder Finders Pty Ltd, third parties
                or the public at large.
              </p>
              <p>
                If you are a registered user of https://builderfinders.com.au/
                and have supplied your email address, Builder Finders Pty Ltd
                may occasionally send you an email to tell you about new
                features, solicit your feedback, or just keep you up to date
                with what’s going on with Builder Finders Pty Ltd and our
                products. We primarily use our blog to communicate this type of
                information, so we expect to keep this type of email to a
                minimum. If you send us a request (for example via a support
                email or via one of our feedback mechanisms), we reserve the
                right to publish it in order to help us clarify or respond to
                your request or to help us support other users. Builder Finders
                Pty Ltd takes all measures reasonably necessary to protect
                against the unauthorised access, use, alteration or destruction
                of potentially personally-identifying and personally-identifying
                information.
              </p>
            </div>
            <div className="space-y-2">
              <h3>Aggregated statistics</h3>
              <p>
                Builder Finders Pty Ltd may collect statistics about the
                behaviour of visitors to its website. Builder Finders Pty Ltd
                may display this information publicly or provide it to others.
                However, Builder Finders Pty Ltd does not disclose your
                personally-identifying information.
              </p>
            </div>
            <div className="space-y-2">
              <h3>Cookies</h3>
              <p>
                To enrich and perfect your online experience, Builder Finders
                Pty Ltd uses “Cookies”, similar technologies and services
                provided by others to display personalized content, appropriate
                advertising and store your preferences on your computer.
              </p>
              <p>
                A cookie is a string of information that a website stores on a
                visitor’s computer, and that the visitor’s browser provides to
                the website each time the visitor returns. Builder Finders Pty
                Ltd uses cookies to help Builder Finders Pty Ltd identify and
                track visitors, their usage of https://builderfinders.com.au/,
                and their website access preferences. Builder Finders Pty Ltd
                visitors who do not wish to have cookies placed on their
                computers should set their browsers to refuse cookies before
                using Builder Finders Pty Ltd.’s websites, with the drawback
                that certain features of Builder Finders Pty Ltd.’s websites may
                not function properly without the aid of cookies.
              </p>
              <p>
                By continuing to navigate our website without changing your
                cookie settings, you hereby acknowledge and agree to Builder
                Finders Pty Ltd.’s use of cookies.
              </p>
            </div>
            <div className="space-y-2">
              <h3>Privacy policy changes</h3>
              <p>
                Although most changes are likely to be minor, Builder Finders
                Pty Ltd may change its Privacy Policy from time to time, and in
                Builder Finders Pty Ltd.’s sole discretion. Builder Finders Pty
                Ltd encourages visitors to frequently check this page for any
                changes to its Privacy Policy. Your continued use of this site
                after any change in this Privacy Policy will constitute your
                acceptance of such change.
              </p>
            </div>
            <div className="space-y-2">
              <h3>Your consent</h3>
              <p>
                By asking us to assist, you consent to the collection and use of
                the information you have provided to us for the purposes
                described above.
              </p>
              <p>
                For more information on your privacy rights please visit
                www.privacy.gov.au
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
